.lead-view {
  /* Styles for lead-view */
    box-sizing: border-box;
    width: 140%;
    margin-top:-55px;
}

.lead-header{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.lead-view-button{
    align-self: center;
    width:200px;
    height:30px;
    font-size: 16px;
    color:blue;
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}
.lead-view-button:hover{
    cursor: pointer;
}

.prev-lead-view {
    margin-right:30px;
    /* Styles for lead-view */
    box-sizing: border-box;
    width:40%;
    padding-left:10px;
    padding-right:10px;
    border: 2px solid rgba(0, 0, 0, 0.09);
    height: 100%;
}

.company-details {
    white-space: pre-line;
    width:70%;
}
.search-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top:40px;
    width:600px;
    height:50px;
}

.preview-company-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size:32px;
    line-height: 34px;
    color: #000000;
}


.company-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size:36px;
    line-height: 34px;
    color: #000000;
}

.company-name:hover {
    cursor: pointer;
    color: #5e5e5e;
}


.company-address {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    margin-top:-10px;
    color: rgba(0, 0, 0, 0.42);

    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}

.preview-company-address{
    width:100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    margin-top:-10px;
    color: rgba(0, 0, 0, 0.42);
}

.search-bar {
    width:300px;
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;
}

.search-bar input {
    flex-grow: 1;
    border: none;
    padding: 10px;
}

.search-icon {
    padding: 10px;
}

.button-group {
    display: flex;
}

.custom-button {
    border: 1px solid lightgrey;
    background-color: transparent;
    margin-left: 10px;
    padding: 7px;
    padding-left:23px;
    padding-right:20px;
    cursor: pointer;
}

.custom-button.active {
    border: 1px solid #57FFB7FF;
    color: #57FFB7FF;
}
h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 29px;
    /* identical to box height */
    margin-top: 50px;

    color: #000000;

}
.employees{
}

table{
    width:100%;
    border-collapse: collapse;
    border:none;
}


.uccevents:hover {
    background-color: #f6f5f5;
}

.lead-view .ucc-events-table .uccevents-sub-row {
    background-color: #e0dfdf;
    margin-left:100px;
}

.uccevents-selected-row {
    background-color: #c4c2c2;
    height:50px;
}


th{
    /* Typography/Body 1 */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    border: none;
    padding: 10px;
    text-align: left;

    letter-spacing: 0.15px;
    border-bottom: 3px solid darkgrey;
    color: #000000;
}
.emp-name, .emp-num{
    width:10px;
}
td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    max-width: 150px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    border: none;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid lightgrey;
    color: #000000;
}

.dropdown-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 3px;
}

.dropdown-button {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    transition: background-color 0.2s;
}

.dropdown-button:hover {
    background-color: #ddd;
}


.ucc-events-heading {
  /* Styles for ucc-events-heading */
}

.ucc-events-table {
  /* Styles for ucc-events-table */
}

.ucc-events{
    padding-bottom: 100px;
}

.ucc-events-table th,
.ucc-events-table td {
  /* Styles for table cells in ucc-events-table */

}
