.Home_Home {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: flex-start;
  width:100%;
}

.Header {
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.HeaderLinks{
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 3%;
}
.Header_User{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: #000000;
  margin-right: 3%;
}
.Header_User_Heading{
  margin:-2px;
  margin-right:15px;
  text-transform: capitalize;
}

.Header_User_FreeSearches{
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
  color: #000000;
}

.Header_User_SignOutBtn{
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 20px;
  text-decoration: none;
  color: #000000;
}

.HeaderUrl {
  text-decoration: none;
  height: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #000000;
  margin-top:5px;
  padding-left: 20px;
}

.Home_Content{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 12%;
  width:100%;
}

.Home_SubTitle{
  margin-top: 0px;
  margin-left: 7%;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.51);
}

.Home_SearchBox {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-left: 10px;
  padding-right:5%;
  width: 100%;
}

.Home_SearchInputs {
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  color: rgba(0, 0, 0, 0.28);
}
.Home_SearchField {
  -webkit-appearance: none; /* For Chrome and Safari */
  -moz-appearance: none; /* For Firefox */
  appearance: none;
  padding-right: 20px;
  width: 40%;
  margin-left:5%;
}

.Home_SearchField > input{
  width: 100%;
}


@media (max-width: 850px){
  .Logo{
    display:none;
  }
  .Home_SearchField{
    width: 70%;
    margin-left:5%;
  }
}
@media (max-width: 600px){

  .Header_User_FreeSearches{
    display:none;
  }

  .Header{
    font-size:14px;
  }

  .Header_User_Heading{
    font-size: 16px;
  }
  .HeaderLinks{
    margin-left: 0%;
  }
  .HeaderUrl{
    padding-left: 10px;
  }

  .Home_TitleContent{
    width:100%;
    padding-left: 10%;
  }
   .Home_TitleContent h1{
    font-size:25px;
  }

}
@media (max-width: 440px){

  .Header_User{
    display:none;
  }

  .Header{
    font-size:14px;
  }
  .HeaderLinks{
    margin-left: 0%;
    width:100%;
  }
  .HeaderUrl{
    padding-left: 10px;
  }
}