.AboutUs > .ABContent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 140px;
}

.AboutUs > .ABContent > p {
    margin-top: 20px;
    margin-left: 15%;
    margin-right: 15%;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.7);
}