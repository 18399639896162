

/* If you have a main container wrapping everything */
.main-container {
    height: 100%;
    margin:0;
}
.SearchResult_SearchResult {
    flex-grow: 1;
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    margin-top: 10px;
    min-height: 90vh;
    max-height: 100vh;
    max-width: 100%;
}
.pdf-wrapper {
    /*max-height: 600px;*/
    overflow: hidden;
}
.SearchResult_PDFViewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: rgba(217, 217, 217, 0.3);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    min-height:90%;
    /*width: 100%;*/
    margin: 5% 5% 5% 0%;
}

.SearchResult_PDFViewer_Nav{
    display:flex;
    flex-direction:row;
    align-items: center;
}
.SearchResult_PDFViewer_Arrow{
    padding: 20px;
    height: 60px;
    width: 60px;
}
.SearchResult_PDFViewer_Arrow:hover{
    color: rgba(157, 157, 157, 0.97);
    cursor: pointer;
}

.SearchResult_Document {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    padding:20px;
}
.SearchResult_Page{
    max-width:90%;
    vertical-align: middle;
    zIndex: 99;
    align-self: center;
    /*background-color:red;*/
}
.Search_Result_col1{
    margin-left:50px;
    margin-top:60px;
    /*width: 100%;*/
    max-width: 500px;
    padding-right: 2%;
}
.Search_Result_col1_back {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.Search_Result_col1_back:hover{
    color: rgba(157, 157, 157, 0.97);
    cursor: pointer;
}
.SearchResult_ResultPreview {
    /*margin-right:100%;*/
    height:fit-content;
    border: 2px solid rgba(0, 0, 0, 0.09);
    /*padding-top: 10px;*/
    /*padding-right: 30px;*/
    overflow:hidden;
    margin: 3% 6% 0 2%;
}

.SearchResult_ResultPreviewHead{
    height:100px;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between; /* Adjusts space between children */
    margin-left: 25px;
    padding-right: 10px;
}

.SearchResult_ResultPreviewHeadLeft{
    display: flex;
    align-items: center;
}
.SearchResult_PreviewHeadCheckbox{
    margin-right: 20px;
}
.SearchResult_ResultPreviewHeadFileMain{
    /*width: 300px;*/
    /*margin-top:-30px;*/
}

.SearchResult_ResultPreviewStatus {
    display:flex;
    flex-direction:column;
    align-items: flex-end;
    margin: 0 1% 0 0;
    padding: 0 0 0 0;
}

.SearchResult_ResultPreviewStatusText{
    max-width: fit-content;
    border: 3px solid;
    border-radius: 20px;
    text-align: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 0;
    font-size:17px;
    margin: 15px 0 10px 0;
    padding: 0 10px 0 10px;
}

.SearchResult_ResultPreviewDateText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.55);
    width:fit-content;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.SearchResult_PreviewHeadFn{
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #000000;
}
.SearchResult_PreviewHeadSub {
    height:20px;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.SearchResult_PreviewHeadState {
    font-family: 'Inter';
    font-style: normal;
    /*font-weight: 700;*/
    font-size: 16px;
    /*line-height: 15px;*/
    color: rgba(0, 0, 0, 0.55);
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    /*background-color: red;*/
}

.SearchResult_ResultPreviewDetailsLst{
    display:flex;
    flex-direction:row;
    justify-content: flex-end; /* change center to flex-end to move icons to the right */
    align-items: center;
}
.SearchPage_ResultPreviewDetails,.SearchPage_ResultPreviewDownload{
    width:33%;
    text-decoration-line: underline;
    color: lightgray; /* change the color to light gray */
    position: relative; /* add this */
    margin-left: auto; /* add this to align elements to the right */
    margin-right: 5px;
    padding-bottom: 5px;
    padding-right:10px;
}
.SearchPage_ResultPreviewDownload{
    margin-top:-2px;
}
.SearchPage_ResultPreviewDetails:hover,.SearchPage_ResultPreviewDownload:hover{
    color: #818181;
}

.SearchResult_ResultPreviewDebtors, .SearchResult_ResultPreviewSP  {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 4%;
    margin: 0 0 0 0;
}

.SearchResult_ResultPreviewDebtorTitle, .SearchResult_ResultPreviewSPTitle{
    /*width:90%;*/
}

.SearchResult_ResultPreviewDebtorLst,.SearchResult_ResultPreviewSPLst{
    /*width:500px;*/
    margin-left: 5%;
    margin-right:2%;
    padding-right: 2%;
    text-transform: capitalize;
}

.SearchResult_ResultPreviewCollatText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.55);
}

.SearchResult_ResultPreviewCollat, .SearchResult_ResultPreviewAmend{
    padding-left: 20px;
    padding-right: 20px;

}
.SearchPage_ResultPreviewAmendSection{
    width:100%
}
.SearchPage_ResultPreviewAmendTitle{
    padding-left: 5%;
}
.SearchResult_ResultPreviewAmendLst{
    display:flex;
    flex-direction:row;
    justify-content: space-between;

}
.SearchResult_ResultPreviewAmendLst:hover{
    background-color: #c4c3c3;
    cursor: pointer;
}

.SearchResult_ResultPreviewAmendContent{
    /*width:100%;*/
    padding: 0 2%;
}

.SearchResult_ResultPreviewAmendLst{
    line-height: 0;
}
@media (max-width: 1170px){


    .SearchResult_ResultPreview {
        /*margin-right:100%;*/
        height:fit-content;
        border: 2px solid rgba(0, 0, 0, 0.09);
        /*padding-top: 10px;*/
        /*padding-right: 30px;*/
        overflow:hidden;
        margin: 3% 3% 0 2%;
    }
    .SearchResult_ResultPreviewHead{
        height:100%;
        width:100%;
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: space-between; /* Adjusts space between children */
        margin-left: 25px;
        padding: 3% 0 0 3%;
    }
    .SearchResult_ResultPreviewStatus {
        display:flex;
        width:100%;
        flex-direction:row;
        margin: 0 1% 0 0;
        padding: 0 0 0 0;
    }
    .SearchResult_ResultPreviewStatusText{
        border: 1px solid;
        border-radius: 20px;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: 0;
        font-size:70%;
        margin: 5px 0 10px 0;
        padding: 0 10px 0 10px;
    }

    .SearchResult_PreviewHeadFn{
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        font-style: normal;
        font-weight: 700;
        font-size: 100%;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #000000;
    }
    .SearchResult_PreviewHeadState {
        font-family: 'Inter';
        font-style: normal;
        /*font-weight: 700;*/
        font-size: 90%;
        /*line-height: 15px;*/
        color: rgba(0, 0, 0, 0.55);
        padding: 5% 0 0 0;
        line-height: 15px;
        /*background-color: red;*/
    }
    .SearchResult_PreviewHeadSub {
        padding: 0 0 0 0;
    }

    .SearchResult_ResultPreviewDateText{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 90%;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.55);
        width:fit-content;
        margin: 0 0 0 0;
        padding: 8% 0 0 0;
    }
    .SearchResult_ResultPreviewAmendLst{
        margin: 0 5%;
        padding: 0 0 2% 0%;
        border-bottom: 1px solid #bdbdbd; /* Adjust the size and color as needed */
    }

    .SearchResult_ResultPreviewAmendLst{
        display:flex;
        flex-direction:column;
        justify-content: space-between;
    }

    .SearchResult_ResultPreviewAmendContent{
        /*width:100%;*/
        margin:3%;
        min-height: 10px;
        /*padding: 0 2%;*/
        line-height: 0px;
    }

    .SearchResult_ResultPreviewAmendLst{
        line-height: 0;
    }
}


@media (max-width: 1000px){
    .SearchResult_SearchResult {
        display:flex;
        flex-direction:column;
        justify-content: flex-start;
        height:200vh;
    }


    .SearchResult_PDFViewer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(217, 217, 217, 0.3);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        min-height:90%;
        margin: 5% 10% 10% 10%;

    }

    .Search_Result_col1{
        margin: 5% 10% 5% 10%;
        /*padding-top:500px;*/
    }

}


@media (max-width: 650px) {

    .SearchResult_ResultPreviewDebtors, .SearchResult_ResultPreviewSP  {
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 0 0 4%;
        margin: 0 0 0 0;
    }
    .SearchResult_ResultPreviewDebtorTitle, .SearchResult_ResultPreviewSPTitle,.SearchResult_ResultPreviewCollatTitle{
        font-size:12px;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
    }

    .SearchResult_ResultPreviewDebtorLst,.SearchResult_ResultPreviewSPLst,.SearchResult_ResultPreviewCollatText{
        font-size:12px;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
    }

    .SearchResult_ResultPreviewCollat, .SearchResult_ResultPreviewAmend{
        padding-left: 10px;
        padding-right: 10px;

    }
    .SearchPage_Results {
        /*margin-right: 15%;*/
        display: flex;
        flex-direction: column;
        margin: 0 0 0 0;
        padding: 4% 0 0 1%;
        width:100%;
        overflow-y: scroll;
        height: 800px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scroll-behavior: smooth;
    }
.SearchResult_ResultPreviewStatus,.SearchResult_PreviewHeadCheckbox{
        display: none;
    }

    .SearchResult_ResultPreviewHead{
        height:100%;
        width:100%;
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: space-between; /* Adjusts space between children */
        margin: 0 0 2% 0;
        padding: 3% 0 10% 5%;
        font-size: 12px;
    }
    .SearchResult_ResultPreviewAmendContent{
        font-size:12px;
        padding: 9px 0;
        margin: 0 0;
    }
    .SearchResult_ResultPreviewAmendLst{
        margin-top:10px;
    }


}