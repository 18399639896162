.SearchPage_SearchMeta {
  display:flex;
  flex-direction:row;
  align-items: center;

  color:rgba(0, 0, 0, 0.44);
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  width: 100%;
  margin-top:-40px;
}
.SearchPage_SearchMeta > p {
  padding: 10px;
}
.SearchPage_SearchMeta > .SearchPage_SortSelect > *{
  border: none;
  outline: none;
}
.SearchPage_SearchHeader > .SearchPage_SelectSearch{
  /*text:red;*/
}
.SearchPage_SearchAgainButton > .SearchPage_SelectSearch > *{
  /*border: none;*/
  /*outline: none;*/
  /*width:200px;*/
  /*margin-left:20px;*/

}
.SearchPage_SearchHeader{
  display:flex;
  flex-direction:row;
  align-items: center;
  margin-left: 25px;
  margin-top:40px;
  margin-right: 0;
}

.SearchPage_Filter{
  display: flex;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
  width:100%;
}

.SearchPage_FilterExactCheckBox{
  /*margin-top: -10px;*/
  height:30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 4px;
  line-height: 15px;
  width: 200px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.SearchPage_FilterExactCheckBox_Small{
  display: none;
}


.SearchPage_FiltInput{
  height:30px;
  width: 18%;
  margin-right:1%;
}
.SearchPage_Search{
  display: flex;
  align-items: center;
  width:90%;

}
.SearchPage_SearchAgainInput{
  width: 80%;
  margin-right:1%;
  margin-left:0%;
}
.SearchPage_SearchButtonContainer{
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
}
.SearchPage_SearchAgainButton{
  background-color: transparent;
  border: none;
}
.SearchPage_FilterButton,.SearchPage_ExportButton{
  min-width: 100px;
  margin-left:1%;
  height: 35px;
  background: #22F69D;
  border:none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color:white;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
 }

.SearchPage_DownUrl{
  margin-top:10px;
  height: 35px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
}
.SearchPage_DownUrl a{
  padding:10px;
}
.SearchPage_FilterButton:hover{
  background: #57ffb7;
}

.SearchPage_LeadView{
  margin-left: 50px;
  padding-left: 100px;
  margin-top:30px;
}
.SearchPage_SearchContent{
  display:flex;
  flex-direction:row;
}

.SearchPage_ResultPreview {
  margin-top:40px;
  margin-right:100%;
  height:fit-content;
  width: 30%;
  margin-left: -50px;
  border: 2px solid rgba(0, 0, 0, 0.09);
}


.SearchPage_Results {
  margin-left: 10%;
  /*margin-right: 15%;*/
  display: flex;
  flex-direction: column;
  padding: 30px;
  width:70%;
  overflow-y: scroll;
  height: 800px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
/* hide scrollbar for chrome, safari and opera */
.SearchPage_Results::-webkit-scrollbar {
  display: none;
}
.SearchPage_SingleResult {
  padding:10px;
}
.SearchPage_ResultHead {
  display:flex;
  flex-direction:row;
  align-items: center;
}

.SearchPage_ResultSP {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #0064FA;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}


.SearchPage_ResultStat {
  padding-left: 10px;
  padding-right: 10px;
  max-width: fit-content;
  border: 1px solid;
  border-radius: 20px;
  margin-left: 10px;
  margin-top: -3px;
  text-align: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 0;
  /*color:#22F69D;*/
}

.SearchPage_BackButton {
  background: transparent;
  border: none;
  color: #6b6bf6;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left:-70px;

}

.SearchPage_BackButton:hover {
  color: #cfcffc;
}

.SearchPage_BackButton svg {
  margin-right: 5px;
}

.SearchPage_ResultCollat {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.55);
  margin-top: 2px;
  width: 100%;
  max-width: 440px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.SearchPage_ResultAmend{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  margin-top: -5px;
}

@media (max-width: 850px){
  .SearchPage_SearchHeader{
    margin-top:2%;
  }
}

@media (max-width: 650px) {
  .SearchPage_Results {
    /*margin-right: 15%;*/
    display: flex;
    flex-direction: column;
    margin: 0 0 0 0;
    padding: 4% 0 0 1%;
    width:100%;
    overflow-y: scroll;
    height: 800px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }
  .Logo,.SearchPage_ResultStat{
    display: none;
  }
  .SearchPage_SearchContent{
    display:flex;
    flex-direction:row;
  }

  .SearchPage_ResultPreview {
    margin-top:40px;
    margin-right:100%;
    height:fit-content;
    width: 30%;
    min-width: 100%;
    margin-left: 0px;
    border: 2px solid rgba(0, 0, 0, 0.09);
  }
  .SearchPage_FilterExactCheckBox_Small{
    display: block;
    height:30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 4px;
    line-height: 15px;
    width: 200px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .SearchPage_FilterExactCheckBox{
    display: none;
  }
  .SearchPage_Search{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:90%;

  }
  .SearchPage_SearchAgainInput{
    width: 80%;
    margin-left:0%;
  }
  .SearchPage_SearchButtonContainer{
    margin-left: -2%;
  }



}