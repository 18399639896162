.FilingSystemPageLayout {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100vw;
  padding-bottom: 50px;
}

.FilingSystemTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  font-weight: normal;
  width: 100%;
}
.FilingSystemSubTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  font-weight: normal;
  width: 100%;
  margin-top: 60px;
}
.FilingSystemInputField {
  width: 100%;
  padding: 8px;
  display: flex;
  margin: 1px;
}

.FilingSystemButton {
  padding: 8px;
  width: 100%;
  height: 100%;
}

.FilingSystemAddAnother {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.FilingSystemTextArea {
  width: 99%;
  height: 200px;
}
.FilingSystemTextAreaWithLabel {
  width: 100%;
  min-height: 50px;
}

.FilingSystemSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  min-width: 300px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.Table {
  width: 100%;
}
.Table > tr {
  text-align: left;
}
