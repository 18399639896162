.LeadSearch_SearchMeta {
  display:flex;
  flex-direction:row;
  align-items: center;

  color:rgba(0, 0, 0, 0.44);
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  width: 100%;
  margin-top:-40px;
}
.LeadSearch_SearchMeta > p {
  padding: 10px;
}
.LeadSearch_SearchMeta > .LeadSearch_SortSelect > *{
  border: none;
  outline: none;
}
.LeadSearch_SearchHeader > .LeadSearch_SelectSearch{
  /*text:red;*/
}
.LeadSearch_SearchAgainButton > .LeadSearch_SelectSearch > *{
  border: none;
  outline: none;
  width:200px;
  margin-left:20px;

}
.LeadSearch_SearchHeader{
  display:flex;
  flex-direction:row;
  align-items: center;
  margin-left: 25px;
  margin-top:40px;
}

.LeadSearch_Filter{
  display: flex;
  margin-top: 0.5%;
  margin-left: 320px;
  margin-right: 5%;
}

.LeadSearch_FilterExactCheckBox{
  margin-top: -10px;
  height:30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  width: 200px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.LeadSearch_FiltInput{
  height:20px;
  width: 18%;
  margin-right:1%;
}
.LeadSearch_Search{
  display: flex;
  align-items: center;
}

.LeadSearch_SearchAgainInput{
  width: 600px;
  margin-right:1%;
  margin-left:70px;
  margin-top: 5px;
}

.LeadSearch_SearchAgainButton{
  background-color: transparent;
  border: none;
}
.LeadSearch_FilterButton,.LeadSearch_ExportButton{
  margin-left:20px;
  width: 100px;
  height: 35px;
  background: #22F69D;
  border:none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color:white;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
 }

.ShowAllCheckBox{
  margin-left:20px;
  width:90px;
}

.LeadSearch_DownUrl{
  margin-top:10px;
  margin-left:20px;
  width: 100px;
  height: 35px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
}
.LeadSearch_DownUrl a{
  padding:10px;
}
.LeadSearch_FilterButton:hover{
  background: #57ffb7;
}

.LeadSearch_LeadView{
  margin-left: 50px;
  padding-left: 100px;
  margin-top:30px;
}
.LeadSearch_SearchContent{
  display:flex;
  flex-direction:row;
}

.LeadSearch_ResultPreview {
  margin-top:40px;
  margin-right:100%;
  height:fit-content;
  width: 30%;
  margin-left: -50px;
  border: 2px solid rgba(0, 0, 0, 0.09);
}

.LeadSearch_ResultPreviewHead{
    display:flex;
    flex-direction:row;
    align-items: center;
    margin-left: 25px;
    margin-top:20px;
}

.SearchResult_PreviewHeadSub {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.LeadSearch_ResultPreviewHeadLeft{
  width: 300px;
}

.LeadSearch_ResultPreviewStatus {
  display:flex;
  flex-direction:column;
  align-items: flex-end;
  padding-left: 15%;
  margin-right: 10px;
}

.LeadSearch_ResultPreviewStatusText{
  margin-top:15px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: fit-content;
  border: 2px solid;
  border-radius: 20px;
  margin-left: 10px;
  text-align: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  line-height: 0;
  font-size:17px;
}

.LeadSearch_ResultPreviewDateText{
  margin-top:10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.55);
  width:fit-content;
}

.LeadSearch_PreviewHeadFn{
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #000000;
}

.LeadSearch_PreviewHeadState,.SearchResult_PreviewHeadType{
  margin-top: -15px;
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
}

.LeadSearch_PreviewHeadState {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.55);
}
.SearchResult_PreviewHeadType{
  color: rgba(0, 0, 0, 0.55);
  margin-left:5px;
}


.LeadSearch_ResultPreviewDetailsLst{
  display:flex;
  flex-direction:row;
  align-items: center;
  padding-left:12%;
}
.LeadSearch_ResultPreviewDetails,.LeadSearch_ResultPreviewDownload,.LeadSearch_ResultPreviewShare{
  width:33%;
  text-decoration-line: underline;
  color:#0064FA;
  padding-left: 20px;
  padding-right: 20px;
}
.LeadSearch_ResultPreviewDownload {
  padding-right: 50px;
  padding-left: 50px;
}

.LeadSearch_ResultPreviewDebtors, .LeadSearch_ResultPreviewSP  {
  display:flex;
  flex-direction:row;
  align-items: center;
  padding-left: 20px;
}

.LeadSearch_ResultPreviewDebtorTitle, .LeadSearch_ResultPreviewSPTitle{
  width:90%;
}

.LeadSearch_ResultPreviewDebtorLst,.LeadSearch_ResultPreviewSPLst{
  width:500px;
  margin-left: 30px;
  margin-right:20px;
  padding-right: 20px;
}

.LeadSearch_ResultPreviewCollatText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.55);
}

.LeadSearch_ResultPreviewCollat, .LeadSearch_ResultPreviewAmend{
  padding-left: 20px;
  padding-right: 20px;

}

.LeadSearch_ResultPreviewAmendLst{
  display:flex;
  flex-direction:row;
  align-items: center;
  padding-left:30px;
}
.LeadSearch_ResultPreviewAmendLst:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}

.LeadSearch_ResultPreviewAmendContent{
 padding-left:10px;
 padding-right:10px;
}

.LeadSearch_ResultPreviewAmendLst{
  line-height: 0;
}

.LeadSearch_Results {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width:60%;
  height: 800px;
  -ms-overflow-style: none;
  overflow-y: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

/* hide scrollbar for chrome, safari and opera */
.LeadSearch_Results::-webkit-scrollbar {
  display: none;
}

.LeadSearch_SingleResult {
  padding:10px;
}
.LeadSearch_SingleResult:hover{
  cursor: pointer;
}
.LeadSearch_ResultHead {
  display:flex;
  flex-direction:row;
  align-items: center;
}

.LeadSearch_ResultSP {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #0064FA;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}


.LeadSearch_ResultStat {
  padding-left: 10px;
  padding-right: 10px;
  max-width: fit-content;
  border: 1px solid;
  border-radius: 20px;
  margin-left: 10px;
  margin-top: -3px;
  text-align: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 0;
  /*color:#22F69D;*/
}

.LeadSearch_BackButton {
  background: transparent;
  border: none;
  color: #6b6bf6;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left:-70px;

}

.LeadSearch_BackButton:hover {
  color: #cfcffc;
}

.LeadSearch_BackButton svg {
  margin-right: 5px;
}

.LeadSearch_ResultCollat {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.55);
  margin-top: 2px;
  width: 440px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.LeadSearch_ResultAmend{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  margin-top: -5px;
}