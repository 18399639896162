.HowItWorks > .HWContent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 140px;
}

.HowItWorks > .HWContent >p, .HowItWorks > .HWContent >h2{
    margin-top: 20px;
    margin-left: 15%;
    margin-right: 15%;
    color: rgba(0, 0, 0, 0.7);
}.HowItWorks > .HWContent >p{
     font-family: 'Inter';
     font-style: italic;
     font-weight: 400;
     font-size: 20px;
     line-height: 24px;
}
.HowItWorks > .HWContent >iframe {
    margin:auto;
}
.HowItWorks > .HWContent >iframe{
    padding-bottom: 100px;
}