/*.Logo{*/
/*    height:80px;*/
/*    width:400px;*/
/*}*/

.landingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    height: 100vh;
}

.topBar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1em;
    position: absolute;
    top: 0;
}

.logoContainer {
    position: relative;
    top: 20px;
    right: -20px;
}

.contentWindow {
    padding-top:15%;
    padding-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-width:73%
}

.imgContainer{
    width:60%;
    padding-left: 5%;
    padding-right: 5%;
}

.imgContainer > img{
    width: 100%;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
}

.arrowButton {
    height: 100%;
    width: 10%;
    font-size: 2em;
    color: #afaeae;
    transition: color 0.3s ease-in-out;
}

.arrowButton:hover {
    color: #5e5e5e;  /* Replace with your desired hover color */
    cursor: pointer;
}


.loginButton {
    /* Button */
    background: #22F69D;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    width: 130px;
    height: 40px;
    border-radius: 4px;
    border: none;
    margin-top:20px;
    margin-right:20px;
    /* Text */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #FFFFFF;
}
.loginButton:hover {
    background: #51fcb2;
    cursor: pointer;
}

@media (max-width: 1300px) {
    .contentWindow {
        padding: 10% 5% 0 5%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content:center;
    }
    .imgContainer{
        padding: 10% 0% 0 0;
        width:100%;
        display: flex;
        align-content: center;
    }

}
